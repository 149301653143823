import React from "react";
import "./styles.css";
import { Benefitsitem } from "./Benefitsitem";

export const Benefits = () => {
  return (
    <div className="container-benefits">
      <div className="container-benefits__info">
        <h2 className="container-benefits__title text-primary-800">
          NUESTROS BENEFICIOS
        </h2>
        <div className="container-benefits__items">
          <Benefitsitem
            image={`/imagenes/account.svg`}
            title={"Acceso Exclusivo para Socios"}
            description={
              "Disfruta de acceso a los cursos como parte de los beneficios de ser socio de Cooperativa Dile."
            }
          />
          <Benefitsitem
            image={`/imagenes/certification.svg`}
            title={"Certificados personalizados"}
            description={
              "Al completar con éxito los cursos, recibirás un certificado personalizado que reflejará tus logros y el conocimiento adquirido."
            }
          />
          <Benefitsitem
            image={`/imagenes/idioma.svg`}
            title={"Incluye curso de idioma"}
            description={
              "Aprende un nuevo idioma con nuestro cursos de Ingles. Mejora tus habilidades lingüísticas y amplía tus oportunidades personales y profesionales."
            }
          />
        </div>
      </div>
    </div>
  );
};
