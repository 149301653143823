import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";

const Footer = () => {
  return (
    <footer className="shadow bg-primary-950">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a
            href="https://escuelagenial.com/aprendedigital/"
            className="flex items-center mb-4 sm:mb-0"
          >
            <div className=" rounded-sm flex items-center justify-center mr-4">
              <img
                src={`${process.env.PUBLIC_URL}/imagenes/logo-removebg-preview.png`}
                className="w-24 h-16"
                alt="AprendeDigital Logo"
              />
            </div>
            <img
                src={`${process.env.PUBLIC_URL}/imagenes/ofilogo.png`}
                className="w-[50%]"
                alt="AprendeDigital Logo"
            />
          </a>
          <ul className="text-2xl font-medium text-primary-600 sm:mb-0 flex space-x-6">
            <li>
              <a href="#" className="hover:underline">
                <BsFacebook className="text-white hover:scale-125"/>
              </a>
            </li>
            <li>
              <a href="https://api.whatsapp.com/send?phone=940070870" className="hover:underline">
                <BsWhatsapp className="text-white hover:scale-125"/>
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center">
          ©{new Date().getFullYear()}
          <a
            href="https://escuelagenial.com/aprendedigital/"
            className="hover:underline"
          >
            {" APRENDE DIGITAL"}
          </a>
          . Todos los derechos reservados.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
