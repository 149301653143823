import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";

import sliders from "../../assets/sliders.json";
import { colors } from "../../assets/colors";

export const Slider = () => {
  const [_prevSlideIndex, setPrevSlideIndex] = useState(1);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);

  const handleSlideChange = (swiper) => {
    setPrevSlideIndex(currentSlideIndex);
    setCurrentSlideIndex(swiper.activeIndex + 1);

    const progressCircle = document.getElementById(
      `contador ${currentSlideIndex}`
    );
    if (progressCircle) {
      progressCircle.style.setProperty("--progress", 1);
    }
  };

  const onAutoplayTimeLeft = (swiper, _time, progress) => {
    const activeIndex = swiper.activeIndex;
    const progressCircle = document.getElementById(
      `contador ${activeIndex + 1}`
    );
    if (progressCircle) {
      progressCircle.style.setProperty("--progress", 1 - progress);
    }
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `
        <div class="${className}">
          <div class="swiper-pagination-circle">
            <div class="swiper-pagination-circle-index">
              ${index + 1}
            </div>
            <svg viewBox="0 0 40 40" id="contador ${index + 1}" >
              <circle cx="20" cy="20" r="18"></circle>
            </svg>
          </div>
        </div>
      `;
    },
  };

  return (
    <div className="">
      <Swiper
        rewind={true}
        effect="slide"
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={pagination}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        parallax={true}
        onSlideChange={handleSlideChange}
        className="slider-container"
      >
        {sliders.map((slider, index) => {
          return (
            <SwiperSlide key={`slider ${index}`} className={`bg-primary-600`}>
              <div className="slider-container__info">
                <div className="slider-info">
                  <div className="slider-info__image">
                    <div className="w-[60%]"></div>
                    <div className="flex-1 2xl:p-10">
                      <img
                        className="mt-[180px] sm:mt-0 mr-[40px] sm:mr-0"
                        src={`${process.env.PUBLIC_URL}${slider.image}`}
                        alt={`slider ${slider.title}`}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="slider-info__left">
                    <h1 className="slider-left__title">
                      {slider.title + " " + slider.titleBold}
                    </h1>
                    <div>
                      <p className="slider-left__description">
                        {slider.description}
                      </p>
                      <a href="https://aprendedigital.pe/classroom/login/index.php">
                      <button
                        className={`slider-left__button ${
                          colors[slider.bgColor]
                        }`}
                      >
                        {slider.textButton}
                      </button></a>                      
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
