import React from "react";
import Partners from "./Partners";

export const PartnersSection = () => {
  return (
    <div className="bg-primary-50 py-2 mt-10">
      <div className="w-[95%] lg:w-[80%] mx-auto px-4 lg:px-8">
        <h2 className="text-2xl lg:text-4xl font-semibold text-center text-primary-800 mb-6">
          MÁS DE 11 MIL SOCIOS CERTIFICADOS DIGITALMENTE
        </h2>
        <Partners />
      </div>
    </div>
  );
};
