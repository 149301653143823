import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";
import { Navigation, Pagination } from "swiper/modules";
import partners from "../../assets/partners.json";

export default function Partners() {
  return (
    <>
      <div className="relative my-5">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          navigation={true}
          modules={[Pagination, Navigation]}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },

            1400: {
              slidesPerView: 3,
            },
          }}
          className="slider-container-partners mt-4"
        >
          {partners.map((partner, index) => (
            <SwiperSlide key={index} className="">
              <div className="swiper-slide1 items-center justify-center h-full py-0 relative group bg-primary-50 border-4 border-primary-100 shadow-md p-6 rounded-lg  hover:shadow-lg transition duration-300 ease-in-out">
                <img
                  className="mb-4 rounded-full w-[180px] h-[180px] relative left-[20%] pt-2"
                  src={`${process.env.PUBLIC_URL}${partner.logo}`}
                  alt={partner.name}
                />
                <h2 className="text-[20px] text-primary-800 pb-3 text-center font-bold">{partner.name}</h2>
                <p className="font-light text-[14px] text-justify text-primary-800">{partner.testimonial}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
