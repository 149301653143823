import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./styles.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isComboOpen, setIsComboOpen] = useState(false);
  const options = [{
    curso:"GESTIÓN EMPRESARIAL DE PYMES",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=2"
  },
  {
    curso:"INGLÉS",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=5"
  },
  {
    curso:"MARKETING PARA PYMES",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=4"
  },
  {
    curso:"INNOVACIÓN Y TECNOLOGÍA DE PYMES",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=3"
  },
  {
    curso:"EDUCACIÓN FINANCIERA",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=6"
  },
  {
    curso:"EXCEL BÁSICO",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=12"
  },
  {
    curso:"FINANZAS INTELIGENTES",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=13"
  },
  {
    curso:"APRENDE A CONTROLAR TUS EMOCIONES",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=16"
  },
  {
    curso:"APRENDE A INVERTIR EN ORO Y CUIDAR TU PATRIMONIO",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=18"
  },
  {
    curso:"INVIERTE EN BITCOIN-CRIPTOMONEDAS",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=19"
  },
  {
    curso:"CERO DEUDAS",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=20"
  },
  {
    curso:"COMO DESCUBRIR OPORTUNIDADES RENTABLES A TU ALREDEDOR",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=21"
  },
  {
    curso:"PROMOCIONAR UN NEGOCIO MIENTRAS TRABAJAS",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=22"
  },
  {
    curso:"CONFIANZA TOTAL",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=23"
  },
  {
    curso:"INTRODUCCIÓN A LA INTELIGENCIA ARTIFICIAL",
    linkPath:"https://aprendedigital.pe/classroom/course/view.php?id=24"
  },



  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleOptions = () => {
    setIsComboOpen(!isComboOpen);
  };

  return (
    <header className="header">
      <nav className="header-navbar">
        <div className="header-navbar-container">
          <div>
            <a href="/">
              <img
                className="h-6"
                src={`${process.env.PUBLIC_URL}/imagenes/ofilogo.png`}
                alt="logo"
                loading="lazy"
              />
            </a>
          </div>

          <div className="header-container-section-right">
            <div
              className="header-section-right__combo"
              onMouseEnter={() => setIsComboOpen(true)}
              onMouseLeave={() => setIsComboOpen(false)}
            >
              <div className="header-combo-container">
                <div
                  className={`header-combo__label ${
                    isComboOpen
                      ? "text-primary-950 bg-primary-50"
                      : "text-primary-50"
                  }`}
                >
                  Cursos
                </div>
                {isComboOpen && (
                  <div className="header-combo__options">
                    {options.map((opti,index) => (
                      <div key={index} className="header-combo__option">
                        <a href={opti.linkPath}><p className="text-primary-50">{opti.curso}</p></a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="py-4">
              <a href="https://aprendedigital.pe/classroom/login/index.php"><button className="header-section-right__button">Ingresar</button></a>
            </div>
            <div className="lg:hidden">
              <button
                className="text-white text-xl mt-2"
                onClick={toggleMenu}
                aria-label="Toggle Menu"
              >
                <FaBars />
              </button>
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="menubar-container">
            <div className="flex justify-end p-4">
              <button
                className="text-white text-lg"
                onClick={toggleMenu}
                aria-label="Close Menu"
              >
                <FaTimes />
              </button>
            </div>
            <ul className="menubar-ul">
              <li className="menubar-ul-li">
                <a href="#" onClick={toggleMenu}>
                  Inicio
                </a>
              </li>
              <li className="menubar-ul-li">
                {
                  <div className="inline-block w-full" onClick={toggleOptions}>
                    <div className="header-combo__label text-left p-0 text-primary-50">
                      Cursos
                    </div>
                    {isComboOpen && (
                      <div className="header-combo__options relative m-0 w-full">
                        {options.map((option) => (
                          <div key={option} className="header-combo__option">
                            {option}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                }
              </li>
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
