import React from "react";
import "./styles.css";

export const Benefitsitem = ({ image, title, description }) => {
  return (
    <div className="container-benefits-item h-[280px]">
      <img
        className="container-benefits-item__image"
        src={`${process.env.PUBLIC_URL}${image}`}
        alt={`${title}`}
        loading="lazy"
      />
      <h3 className="container-benefits-item__title text-primary-800">{title}</h3>
      <p className="container-benefits-item__description text-primary-800 font-light">{description}</p>
    </div>
  );
};
