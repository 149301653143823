import "./App.css";
import { Benefits } from "./components/benefits/Benefits";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import { PartnersSection } from "./components/partners/PartnersSection";
import { Slider } from "./components/slider/Slider";

function App() {
  return (
    <>
      <Navbar />
      <Slider />
      <Benefits />
      <PartnersSection />
      <Footer />
    </>
  );
}

export default App;
